import { Container } from './MainNav.styles';
import { ReactNode, memo } from 'react';

function MainNavPureComponent({
  children,
  mobileIsOpen = false,
}: {
  children?: ReactNode;
  mobileIsOpen?: boolean;
}) {
  return <Container mobileIsOpen={mobileIsOpen}>{children}</Container>;
}

export const MainNav = memo(MainNavPureComponent);
