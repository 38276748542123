import * as React from 'react';

const SvgCloseCross = (
  props: React.SVGProps<SVGSVGElement> & { title?: string },
) => (
  <svg
    width={32}
    height={32}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <title>{props?.title || 'close cross'}</title>
    <path
      d="m25.333 8.547-1.88-1.88L16 14.12 8.546 6.667l-1.88 1.88L14.12 16l-7.453 7.453 1.88 1.88L16 17.88l7.453 7.453 1.88-1.88L17.88 16l7.453-7.453Z"
      fill="#797A7C"
    />
  </svg>
);

export default SvgCloseCross;
