import { ReactNode, memo } from 'react';
import { Anchor } from './MainNavItem.styles';
import Link from 'next/link';
import type { VariantProps } from '@stitches/react';

function MainNavItemPureComponent({
  active,
  children,
  linkUrl,
  linkUrlLang,
}: {
  active?: VariantProps<typeof Anchor>['active'];
  children?: ReactNode;
  linkUrl: string;
  linkUrlLang?: string;
}) {
  return (
    <Link href={linkUrl} hrefLang={linkUrlLang} passHref={true}>
      <Anchor active={active}>{children}</Anchor>
    </Link>
  );
}

export const MainNavItem = memo(MainNavItemPureComponent);
