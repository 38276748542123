import type { CSS, VariantProps } from '@stitches/react';
import { ReactNode, memo } from 'react';
import { Container } from './LayoutFlow.styles';

function LayoutFlowPureComponent({
  align,
  alignItems,
  children,
  direction,
  gap,
  reverse,
  wrap,
  css,
}: {
  align?: CSS['justifyContent'];
  alignItems?: CSS['alignItems'];
  children?: ReactNode;
  direction?: CSS['flexDirection'];
  gap?: CSS['gap'];
  reverse?: VariantProps<typeof Container>['reverse'];
  wrap?: VariantProps<typeof Container>['wrap'];
  css?: CSS;
}) {
  return (
    <Container
      css={{
        alignItems,
        flexDirection: direction,
        gap,
        justifyContent: align,
        ...css,
      }}
      reverse={reverse}
      wrap={wrap}
    >
      {children}
    </Container>
  );
}

export const LayoutFlow = memo(LayoutFlowPureComponent);
