import {
  ChangeEvent,
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useState,
  useEffect,
} from 'react';

type Validator = (value: string) => boolean;

type Field = { valid: boolean; validator?: Validator; value: string };
type Form = { fields: Map<string, Field>; valid: boolean };
type Forms = Map<string, Form>;

type FormContextType = {
  forms: Forms;
  setForms: Dispatch<SetStateAction<Forms>>;
};

const initialData: FormContextType = {
  forms: new Map(),
  setForms: () => {},
};

const FormContext = createContext(initialData);

export function FormProvider({ children }: { children: ReactNode }) {
  const [forms, setForms] = useState<Forms>(new Map());
  return (
    <FormContext.Provider value={{ forms, setForms }}>
      {children}
    </FormContext.Provider>
  );
}

function isFormValid(fields: Form['fields']) {
  for (const { valid } of fields.values()) {
    if (!valid) {
      return false;
    }
  }
  return true;
}

function validateForm(currentForm: Form) {
  const fields = new Map(currentForm.fields);

  let hasChanges = false;
  let formValid = true;
  for (const [fieldId, field] of fields) {
    const { valid: currentValid, validator, value } = field;
    if (validator) {
      const valid = validator(value);
      if (valid !== currentValid) {
        fields.set(fieldId, { ...field, valid });
        hasChanges = true;
      }
      formValid &&= valid;
    }
  }

  if (hasChanges) {
    const form = { ...currentForm, fields, valid: formValid };
    return form;
  }

  return currentForm;
}

export function useForm(formId: string) {
  const { forms, setForms } = useContext(FormContext);

  const valid = forms.get(formId)?.valid ?? true;

  const tryCollect = useCallback(() => {
    const currentForm = forms.get(formId);

    if (!currentForm) {
      return {};
    }

    const form = validateForm(currentForm);

    if (form !== currentForm) {
      const newForms = new Map(forms);
      newForms.set(formId, form);
      setForms(newForms);
    }

    if (form.valid) {
      return Object.fromEntries(
        Array.from(form.fields).map(([fieldId, field]) => [
          fieldId,
          field.value,
        ]),
      );
    }

    return undefined;
  }, [forms, formId, setForms]);

  const validate = useCallback(() => {
    setForms((currentForms) => {
      const currentForm = currentForms.get(formId);

      if (!currentForm) {
        return currentForms;
      }

      const form = validateForm(currentForm);
      if (form !== currentForm) {
        const forms = new Map(currentForms);
        forms.set(formId, form);
        return forms;
      }

      return currentForms;
    });
  }, [setForms, formId]);

  return { tryCollect, valid, validate };
}

export function useFormInput(
  formId: string,
  fieldId: string,
  config?: { validator?: Validator },
) {
  const { forms, setForms } = useContext(FormContext);

  const field = forms.get(formId)?.fields.get(fieldId);
  const valid = field?.valid ?? true;
  const value = field?.value ?? '';

  useEffect(() => {
    setForms((currentForms) => {
      const currentForm: Form = currentForms.get(formId) ?? {
        fields: new Map(),
        valid: true,
      };
      const currentField = currentForm.fields.get(fieldId) ?? {
        valid: true,
        value: '',
      };

      if (currentField?.validator !== config?.validator) {
        const forms = new Map(currentForms);
        const form = { ...currentForm, fields: new Map(currentForm.fields) };

        form.fields.set(fieldId, {
          ...currentField,
          validator: config?.validator,
        });
        forms.set(formId, form);

        return forms;
      }

      return currentForms;
    });
  }, [setForms, formId, fieldId, config?.validator]);

  const onBlur = useCallback(() => {
    setForms((currentForms) => {
      const currentForm: Form = currentForms.get(formId) ?? {
        fields: new Map(),
        valid: true,
      };
      const currentField: Field = currentForm.fields.get(fieldId) ?? {
        valid: true,
        value: '',
      };

      if (currentField.validator) {
        const valid = currentField.validator(currentField.value);

        if (valid !== currentField.valid) {
          const forms = new Map(currentForms);
          const form = {
            ...currentForm,
            fields: new Map(currentForm.fields),
            valid: isFormValid(currentForm.fields),
          };

          form.fields.set(fieldId, { ...currentField, valid });
          forms.set(formId, form);

          return forms;
        }
      }

      return currentForms;
    });
  }, [setForms, formId, fieldId]);

  const onChange = useCallback(
    (eventArgs: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const { value } = eventArgs.currentTarget;

      setForms((currentForms) => {
        const currentForm: Form = currentForms.get(formId) ?? {
          fields: new Map(),
          valid: true,
        };
        const currentField: Field = currentForm.fields.get(fieldId) ?? {
          valid: true,
          validator: config?.validator,
          value: '',
        };

        const forms = new Map(currentForms);
        const form = { ...currentForm, fields: new Map(currentForm.fields) };

        if (currentField.validator && !currentField.valid) {
          const valid = currentField.validator(value);
          form.fields.set(fieldId, { ...currentField, valid, value });
          form.valid = isFormValid(form.fields);
        } else {
          form.fields.set(fieldId, { ...currentField, value });
        }

        forms.set(formId, form);

        return forms;
      });
    },
    [setForms, formId, fieldId, config?.validator],
  );

  return { onBlur, onChange, valid, value };
}
