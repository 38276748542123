import { createStitches } from '@stitches/react';
import type * as Stitches from '@stitches/react';

export const style = {
  prefix: '',
  theme: {
    colors: {
      'skin-black': '#101820',
      'skin-grey-shade2': '#797A7C',
      'skin-grey-shade1': '#8E8F90',
      'skin-grey': '#A7A8A9',
      'skin-grey-lighten1': '#D6D6D7',
      'skin-grey-lighten2': '#F3F3F3',
      'skin-white-smoke': '#F7F7F7',
      'skin-white': '#FFFFFF',
      'skin-accent1-shade2': '#A17517',
      'skin-accent1-shade1': '#C48F1C',
      'skin-accent1': '#E0A527',
      'skin-accent1-lighten1': '#E9BE63',
      'skin-accent1-lighten2': '#F2DAA5',
      'skin-accent1-lighten3': '#FFF7E6',
      'skin-red': '#FC000A',
      'skin-green': '#33AD55',
    },
    space: {
      'spacing-1x': '8px',
      'spacing-2x': '16px',
      'spacing-3x': '24px',
      'spacing-4x': '32px',
      'spacing-5x': '40px',
      'spacing-6x': '48px',
      'spacing-7x': '56px',
      'spacing-8x': '64px',
      'spacing-10x': '80px',
      'spacing-14x': '112px',
      'spacing-15x': '120px',
      spacingMainMenuTabletAndUp: '104px',
      spacingMainMenu: '209px',
      spacingMainMobile: '5%',
    },
    fontSizes: {
      xs: '0.875rem', // 14px
      s: '1rem', // 16px
      m: '1.125rem', // 18px
      l: '1.25rem', // 24px
      xl: '1.5rem', // 24px
      xxl: '1.75rem', // 32px
      xxxl: '2.5rem', // 40px
      xxxxl: '3.25rem', // 52px
    },
    fontWeights: {
      regular: '400',
      bold: '700',
    },
    fonts: {
      default: 'Verdana, sans-serif',
    },
    letterSpacings: {},
    borderWidths: {
      'border-width-regular': '1px',
      'border-width-medium': '2px',
    },
    radii: {
      none: '0px',
      rounded: '5px',
      circle: '100px',
    },
    shadows: {
      'shadow-1': '0px 8px 24px rgba(0, 0, 0, 0.08)',
      'shadow-2': '0px 8px 24px rgba(0, 0, 0, 0.16)',
      'shadow-3': '2px 2px 5px 0px #0000001F inset',
    },
    lineHeights: {
      xs: 'calc(21/14)',
      s: 'calc(24/16)',
      m: 'calc(28/18)',
      l: 'calc(30/20)',
      xl: 'calc(32/24)',
      xxl: 'calc(34/28)',
      xxxl: 'calc(48/40)',
      xxxxl: 'calc(60/52)',
    },
    zIndices: {
      below: '-1000',
      regular: '0',
      higher: '10',
      top: '1000',
    },
    sizes: {
      textContent: '80ch',
      tablet: '34.375rem',
      laptop: '69.375rem',
      desktop: '93rem',
    },
    transitions: {
      'shadow-hover': 'box-shadow 0.2s',
    },
  },
  // @see https://pcode-at.slack.com/archives/CGQSXG822/p1661604379139129
  media: {
    mobileOnly: '(max-width: 34.375rem)',
    tabletAndUp: '(min-width: 34.375rem)', // > 550px
    laptopAndUp: '(min-width: 69.375rem)', // > 1110px
    desktopAndUp: '(min-width: 93rem)', // > 1500px
  },
  utils: {},
};

export const { styled, css, config, globalCss, getCssText } =
  createStitches(style);
export type CSS = Stitches.CSS<typeof config>;
