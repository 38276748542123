import { H } from 'react-headings';
import { styled } from '../stitches.config';

export const BodyRegularStyle = {
  fontFamily: '$default',
  fontSize: '$xs',
  lineHeight: '$xs',
  fontWeight: '$regular',
  textDecoration: 'none',
};

export const LinkStyle = {
  ...BodyRegularStyle,

  color: '$skin-accent1-shade1',
  transition: 'all .2s ease-in-out',

  '&:hover': {
    textDecoration: 'underline',
    color: '$skin-accent1-shade2',
  },
};

export const FooterRegularStyle = {
  fontFamily: '$default',
  fontSize: '$xs',
  lineHeight: '$xs',
  fontWeight: '$regular',
  textDecoration: 'none',
};

export const ButtonStyle = {
  fontFamily: '$default',
  fontSize: '$xs',
  lineHeight: '$xs',
  fontWeight: '$bold',
  textDecoration: 'none',
};

export const H1Style = {
  fontFamily: '$default',
  fontSize: '$xxl',
  lineHeight: '$xxl',
  fontWeight: '$bold',
  textDecoration: 'none',
  hyphens: 'manual',
};

export const H1TabletAndUpStyle = {
  ...H1Style,
  fontSize: '$xxxxl',
  lineHeight: '$xxxxl',
};

export const H2Style = {
  fontFamily: '$default',
  fontSize: '$xl',
  lineHeight: '$xl',
  fontWeight: '$regular',
  textDecoration: 'none',
  hyphens: 'manual',
};

export const H2TabletAndUpStyle = {
  ...H2Style,
  fontSize: '$xxxl',
  lineHeight: '$xxxl',
};

export const SubtitleStyle = {
  fontFamily: '$default',
  fontSize: '$l',
  lineHeight: '$l',
  fontWeight: '$regular',
  textDecoration: 'none',
  hyphens: 'manual',
};

export const H3Style = {
  fontFamily: '$default',
  fontSize: '$m',
  lineHeight: '$m',
  fontWeight: '$bold',
  textDecoration: 'none',
  hyphens: 'manual',
};

export const H4Style = {
  fontFamily: '$default',
  fontSize: '$m',
  lineHeight: '$m',
  fontWeight: '$regular',
  textDecoration: 'none',
  hyphens: 'manual',
};

export const H4TabletAndUpStyle = {
  fontFamily: '$default',
  fontSize: '$l',
  lineHeight: '$l',
  fontWeight: '$regular',
  textDecoration: 'none',
};

export const H5Style = {
  fontFamily: '$default',
  fontSize: '$s',
  lineHeight: '$s',
  fontWeight: '$bold',
  textDecoration: 'none',
  hyphens: 'manual',
};

export const H6Style = {
  fontFamily: '$default',
  fontSize: '$s',
  lineHeight: '$s',
  fontWeight: '$regular',
  textDecoration: 'none',
  hyphens: 'manual',
};

const typographyComposers = {
  color: '$skin-black',
  variants: {
    variant: {
      h1: {
        ...H1Style,
      },
      h1TabletAndUp: {
        ...H1TabletAndUpStyle,
      },
      h2: {
        ...H2Style,
      },
      h2TabletAndUp: {
        ...H2TabletAndUpStyle,
      },
      subtitle: {
        ...SubtitleStyle,
      },
      h3: {
        ...H3Style,
      },
      h4: {
        ...H4Style,
      },
      h4Link: {
        ...LinkStyle,
        ...H4Style,
      },
      h4TabletAndUp: {
        ...H4TabletAndUpStyle,
      },
      h4LinkTabletAndUp: {
        ...LinkStyle,
        ...H4TabletAndUpStyle,
      },
      h5: {
        ...H5Style,
      },
      h6: {
        ...H6Style,
      },
      body: {
        ...BodyRegularStyle,
      },
      link: {
        ...LinkStyle,
      },
      button: {
        ...ButtonStyle,
      },
      footer: {
        ...FooterRegularStyle,
      },
    },
    color: {
      skinBlack: {
        color: '$skin-black',
      },
      skinGrey: {
        color: '$skin-grey',
      },
      skinGreyShade1: {
        color: '$skin-grey-shade-1',
      },
      skinAccent1Shade1: {
        color: '$skin-accent1-shade1',
      },
      skinWhite: {
        color: '$skin-white',
      },
    },
    align: {
      left: {
        textAlign: 'left',
      },
      center: {
        textAlign: 'center',
      },
      right: {
        textAlign: 'right',
      },
    },
  },
  defaultVariants: {
    color: 'skinBlack',
    variant: 'body-regular',
    align: 'left',
  },
};

export const Typo = styled('span', typographyComposers);
export const CopyText = styled('p', typographyComposers);
export const StyledLink = styled('a', typographyComposers);
export const Heading = styled(H, typographyComposers);
