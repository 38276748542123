import { styled } from '../../../stitches.config';

export const Container = styled('div', {
  display: 'flex',
  variants: {
    reverse: {
      true: {
        flexDirection: 'row-reverse',
      },
    },
    wrap: {
      wrap: {
        flexWrap: 'wrap',
      },
      wrapReverse: {
        flexWrap: 'wrap-reverse',
      },
    },
  },
});
