import { keyframes } from '@stitches/react';
import { styled } from '../../../stitches.config';

export const Button = styled('button', {
  display: 'flex',
  height: '32px',
  width: '32px',

  '@laptopAndUp': {
    display: 'none',
  },
});

const flyIn = keyframes({
  '0%': {
    transform: 'translateX(100%)',
  },
  '100%': {
    transform: 'translateX(0)',
  },
});

const flyOut = keyframes({
  '0%': {
    transform: 'translateX(0)',
  },
  '100%': {
    transform: 'translateX(100%)',
  },
});

export const MobileMenu = styled('div', {
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  background: '$skin-white',
  zIndex: '$top',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-end',
  justifyContent: 'space-between',
  padding: '$spacingMainMobile',

  '@laptopAndUp': {
    transform: 'translateX(100%)',
  },

  variants: {
    isOpen: {
      initial: {
        display: 'none',
      },
      true: {
        transform: 'translateX(0)',
        animation: `${flyIn} 0.3s ease-in-out`,
      },
      false: {
        transform: 'translateX(100%)',
        animation: `${flyOut} 0.3s ease-in-out`,
      },
    },
  },
});
