import { styled } from '../../../stitches.config';

export const Container = styled('div', {
  display: 'block',
  variants: {
    variant: {
      display: {
        fontSize: '$xxxl',
        fontWeight: '$bold',
      },
      h1bold: {
        fontSize: '$xxl',
        fontWeight: '$bold',
      },
      h2bold: {
        fontSize: '$xl',
        fontWeight: '$bold',
      },
      h3bold: {
        fontSize: '$l',
        fontWeight: '$bold',
      },
      h4bold: {
        fontSize: '$m',
        fontWeight: '$bold',
      },
      h5bold: {
        fontSize: '$s',
        fontWeight: '$bold',
      },
      h6bold: {
        fontSize: '$xs',
        fontWeight: '$bold',
      },
      blockquoteRegular: {
        fontSize: '$l',
        fontWeight: '$regular',
      },
      bodyLargeRegular: {
        fontSize: '$l',
        fontWeight: '$regular',
      },
      bodyMediumRegular: {
        fontSize: '$m',
        fontWeight: '$regular',
      },
      bodySmallRegular: {
        fontSize: '$s',
        fontWeight: '$regular',
      },
      linkLargeBold: {
        fontSize: '$l',
        fontWeight: '$bold',
      },
      linkMediumBold: {
        fontSize: '$m',
        fontWeight: '$bold',
      },
      linkSmallBold: {
        fontSize: '$s',
        fontWeight: '$bold',
      },
    },
  },
});
