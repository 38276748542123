import {
  BodyRegularStyle,
  H1TabletAndUpStyle,
  H2TabletAndUpStyle,
  H3Style,
  H4TabletAndUpStyle,
  H5Style,
  H6Style,
} from './Typography';
import { globalCss } from '../stitches.config';

export const globalStyles = globalCss({
  /***
   The new CSS reset - version 1.7.3 (last updated 7.8.2022)
   GitHub page: https://github.com/elad2412/the-new-css-reset
   ***/
  /*
    Remove all the styles of the "User-Agent-Stylesheet", except for the 'display' property
    - The "symbol *" part is to solve Firefox SVG sprite bug
 */
  '*:where(:not(html, iframe, canvas, img, svg, video, audio):not(svg *, symbol *))':
    {
      all: 'unset',
      display: 'revert',
    },

  /* Preferred box-sizing value */
  '*, *::before, *::after': {
    'box-sizing': 'border-box',
  },
  /* Reapply the pointer cursor for anchor tags */
  'a, button': {
    cursor: 'revert',
  },
  /* Remove list styles (bullets/numbers) */
  'ol, ul, menu': {
    'list-style': 'none',
  },
  /* For images to not be able to exceed their container */
  img: {
    'max-width': '100%',
  },
  /* removes spacing between cells in tables */
  table: {
    'border-collapse': 'collapse',
  },
  /* Safari - solving issue when using user-select:none on the <body> text input doesn't working */
  'input, textarea': {
    '-webkit-user-select': 'auto',
  },
  /* revert the 'white-space' property for textarea elements on Safari */
  textarea: {
    'white-space': 'revert',
  },
  /* minimum style to allow to style meter element */
  meter: {
    '-webkit-appearance': 'revert',
    appearance: 'revert',
  },
  /* reset default text opacity of input placeholder */
  '::placeholder': {
    color: 'unset',
  },
  /* fix the feature of 'hidden' attribute.
     display:revert; revert to element instead of attribute */
  ':where([hidden])': {
    display: 'none',
  },
  /* revert for bug in Chromium browsers
   - fix for the content editable attribute will work properly.
   - webkit-user-select: auto; added for Safari in case of using user-select:none on wrapper element*/
  ':where([contenteditable]:not([contenteditable="false"]))': {
    '-moz-user-modify': 'read-write',
    '-webkit-user-modify': 'read-write',
    'overflow-wrap': 'break-word',
    '-webkit-line-break': 'after-white-space',
    '-webkit-user-select': 'auto',
  },
  /* apply back the draggable feature - exist only in Chromium and Safari */
  ':where([draggable="true"])': {
    '-webkit-user-drag': 'element',
  },

  body: {
    color: '$skin-black',
    ...BodyRegularStyle,
    wordWrap: 'break-word',
    overflowWrap: 'break-word',
    WebkitHyphens: 'auto',
    MozHyphens: 'auto',
    MsHyphens: 'auto',
    hyphens: 'auto',
  },
  svg: {
    width: '100%',
    height: 'auto',
  },

  // see https://www.tpgi.com/focus-visible-and-backwards-compatibility/
  '&:focus': {
    outline: '$statos-500 solid $borderWidths$medium',
  },
  '&:focus:not(:focus-visible)': {
    outline: 'none',
  },
  '&:focus-visible': {
    outline: '$statos-500 solid $borderWidths$medium',
    outlineOffset: '1px',
  },

  // TinaCMS Overrides / Bugfixes
  // disable image in mdx editor
  // @TODO: remove when API is available to configure slate editor toolbar -> https://discord.com/channels/835168149439643678/835172431836479508/919638502025609306
  'span[data-testid="ToolbarButton"]:nth-child(5)': {
    display: 'none',
  },
  // disable strikethrough in mdx editor
  // @TODO: remove when API is available to configure slate editor toolbar -> https://discord.com/channels/835168149439643678/835172431836479508/919638502025609306
  'span[data-testid="ToolbarButton"]:nth-child(10)': {
    display: 'none',
  },
  // disable underline in mdx editor
  // @TODO: remove when API is available to configure slate editor toolbar -> https://discord.com/channels/835168149439643678/835172431836479508/919638502025609306
  'span[data-testid="ToolbarButton"]:nth-child(11)': {
    display: 'none',
  },

  // make sidebar in tina-admin smaller
  'div.flex.flex-col.w-80.flex-shrink-0.bg-gradient-to-b.from-white.to-gray-50.border-r.border-gray-200':
    {
      width: '15rem',
    },

  // unreset svgs in the tina-admin
  '.tina-tailwind svg': {
    width: 'initial',
  },

  // unreset textareas in the tina-admin
  '.tina-tailwind textarea': {
    background: 'white',
    border: 'rgb(225 221 236 / var(--tw-border-opacity)) solid thin',
  },

  // rich-text textarea formatting

  '.tina-tailwind h1.slate-h1': {
    ...H1TabletAndUpStyle,
    marginBottom: '$spacing-5x',
  },

  '.tina-tailwind h2.slate-h2': {
    ...H2TabletAndUpStyle,
    marginBottom: '$spacing-3x',
  },

  '.tina-tailwind h3.slate-h3': {
    ...H3Style,
    marginBottom: '$spacing-2x',
  },

  '.tina-tailwind h4.slate-h4': {
    ...H4TabletAndUpStyle,
    marginBottom: '$spacing-1x',
  },

  '.tina-tailwind h5.slate-h5': {
    ...H5Style,
    marginBottom: '$spacing-1x',
  },

  '.tina-tailwind h6.slate-h6': {
    ...H6Style,
    marginBottom: '$spacing-1x',
  },

  '.tina-tailwind .tina-prose p.slate-p, .tina-tailwind .tina-prose [data-slate-editor="true"] p:first-of-type':
    {
      ...BodyRegularStyle,
      marginTop: 0,
      marginBottom: '$spacing-2x',
    },

  '.tina-tailwind .slate-italic': {
    fontStyle: 'italic',
  },

  // rich-text heading dropdown

  '.tina-tailwind .tina-prose h1': {
    ...H1TabletAndUpStyle,
  },

  '.tina-tailwind .tina-prose h2': {
    ...H2TabletAndUpStyle,
  },

  '.tina-tailwind .tina-prose h3': {
    ...H3Style,
  },

  '.tina-tailwind .tina-prose h4': {
    ...H4TabletAndUpStyle,
  },

  '.tina-tailwind .tina-prose h5': {
    ...H5Style,
  },

  '.tina-tailwind .tina-prose h6': {
    ...H6Style,
  },

  '.tina-tailwind .tina-prose p': {
    ...BodyRegularStyle,
  },

  // make heading dropdown in rich-text larger
  '.tina-tailwind .origin-top-left.absolute.left-0.mt-2.-mr-1.w-56.rounded-md':
    {
      width: '320px',
    },

  // hide code button in rich-text
  '.tina-tailwind span[data-test="codeOverflowButton"]': {
    display: 'none',
  },

  // hide codeBlock button in rich-text
  '.tina-tailwind span[data-test="codeBlockOverflowButton"]': {
    display: 'none',
  },
});
