import { ElementType, ReactNode, memo } from 'react';
import type { CSS } from '@stitches/react';
import { Container } from './LayoutText.styles';

function LayoutTextPureComponent({
  align,
  children,
  color,
  component,
  lineHeight,
}: {
  align?: CSS['textAlign'];
  children?: ReactNode;
  color?: CSS['color'];
  component?: ElementType;
  lineHeight?: CSS['lineHeight'];
}) {
  return (
    <Container as={component} css={{ color, lineHeight, textAlign: align }}>
      {children}
    </Container>
  );
}

export const LayoutText = memo(LayoutTextPureComponent);
