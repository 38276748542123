import { styled } from '../../../stitches.config';

export const Container = styled('nav', {
  display: 'none',
  '@laptopAndUp': {
    display: 'flex',
  },

  variants: {
    mobileIsOpen: {
      true: {
        display: 'flex',
        flexDirection: 'column',
      },
      false: {
        display: 'none',
        '@laptopAndUp': {
          display: 'flex',
        },
      },
    },
  },
});
