import { styled } from '../../../stitches.config';

export const Anchor = styled('a', {
  color: 'inherit',
  display: 'block',
  hyphens: 'manual',

  textDecoration: 'inherit',
  variants: {
    active: {
      true: { borderColor: '$skin-accent1' },
    },
  },
  borderRightColor: 'transparent',
  borderRightStyle: 'solid',
  borderRightWidth: '3px',
  padding: '$spacing-1x $spacing-4x',
  textAlign: 'right',
  fontSize: '$xxl',
  '@laptopAndUp': {
    borderBottomColor: 'transparent',
    borderBottomStyle: 'solid',
    borderBottomWidth: '3px',
    padding: '$spacing-5x $spacing-2x',
    fontSize: 'inherit',
    textAlign: 'left',
    borderRight: 'none',
    '&:hover': {
      backgroundColor: '$skin-white-smoke',
    },
  },
});
