import { Container, Content } from './LayoutMenuMain.styles';
import { ReactNode, memo } from 'react';

function LayoutMenuMainPureComponent({ children }: { children?: ReactNode }) {
  return (
    <Container>
      <Content>{children}</Content>
    </Container>
  );
}

export const LayoutMenuMain = memo(LayoutMenuMainPureComponent);
