import { styled } from '../../../stitches.config';

export const Container = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
  position: 'sticky',
  top: 0,
  zIndex: '$top',
  background: '$skin-white',
  '@laptopAndUp': {
    padding: '0 $spacing-5x',
  },
});

export const Content = styled('div', {
  alignItems: 'stretch',
  flexDirection: 'column',
  display: 'flex',
  justifyContent: 'space-between',
  maxWidth: '$desktop',
  width: '100%',
  '@laptopAndUp': {
    alignItems: 'center',
    flexDirection: 'row',
  },
});
