import { Button, MobileMenu } from './MenuButton.styles';
import { ReactNode, memo, useState, useEffect } from 'react';
import SvgHamburger from '../svg/SvgHamburger';
import Link from 'next/link';
import LogoMain from '../svg/SvgLogo';
import SvgCloseCross from '../svg/SvgCloseCross';
import { Flex } from '../Flex';
import { MainNav } from '../MainNav';
import { MainNavItem } from '../MainNavItem';
import { useRouter } from 'next/router';
import { LayoutFlow } from '../LayoutFlow';
import { CopyText } from 'shared/Typography';

function MenuButtonPureComponent() {
  const { route } = useRouter();
  const [isOpen, setIsOpen] = useState<'initial' | 'true' | 'false'>('initial');

  useEffect(() => {
    if (isOpen != 'initial') {
      setIsOpen('false');
    }
  }, [route]);

  return (
    <>
      <Button
        onClick={() => {
          if (isOpen === 'true') {
            setIsOpen('false');
          } else {
            setIsOpen('true');
          }
        }}
      >
        <SvgHamburger />
      </Button>
      <MobileMenu isOpen={isOpen}>
        <Flex
          css={{
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <Link href="/">
            <a>
              <LogoMain style={{ height: '4em' }} />
            </a>
          </Link>
          <Button
            onClick={() => {
              if (isOpen === 'true') {
                setIsOpen('false');
              } else {
                setIsOpen('true');
              }
            }}
          >
            <SvgCloseCross />
          </Button>
        </Flex>
        <MainNav mobileIsOpen={true}>
          <MainNavItem active={route === '/'} linkUrl="/">
            Home
          </MainNavItem>
          <MainNavItem
            active={route.startsWith('/ueber-mich')}
            linkUrl="/ueber-mich"
          >
            Über mich
          </MainNavItem>
          <MainNavItem
            active={route.startsWith('/leistungen-preise')}
            linkUrl="/leistungen-preise"
          >
            Leistungen & Preise
          </MainNavItem>
          <MainNavItem active={route.startsWith('/kontakt')} linkUrl="/kontakt">
            Kontakt
          </MainNavItem>
        </MainNav>
        <LayoutFlow
          gap="$spacing-3x"
          css={{
            flexDirection: 'column',
          }}
        >
          <Link href="/agb" passHref={true}>
            <CopyText align="right" color="skinGrey" variant="body" as="a">
              AGBs
            </CopyText>
          </Link>
          <Link href="/datenschutz" passHref={true}>
            <CopyText align="right" color="skinGrey" variant="body" as="a">
              Datenschutz
            </CopyText>
          </Link>
          <Link href="/impressum" passHref={true}>
            <CopyText align="right" color="skinGrey" variant="body" as="a">
              Impressum
            </CopyText>
          </Link>
        </LayoutFlow>
      </MobileMenu>
    </>
  );
}

export const MenuButton = memo(MenuButtonPureComponent);
