import type { AppProps } from 'next/app';
import { LayoutFlow } from '../components/atoms/LayoutFlow';
import { LayoutMenuMain } from '../components/atoms/LayoutMenuMain';
import { LayoutText } from '../components/atoms/LayoutText';
import Link from 'next/link';
import LogoMain from '../components/atoms/svg/SvgLogo';
import { MainNav } from '../components/atoms/MainNav';
import { MainNavItem } from '../components/atoms/MainNavItem';
import { globalStyles } from '../shared/globalStyles';
import { useRouter } from 'next/router';
import React from 'react';
import { CopyText } from 'shared/Typography';
import { Flex } from 'components/atoms/Flex';
import { MenuButton } from 'components/atoms/MenuButton';
import { FormProvider } from 'context/form';

const setSmoothScroll = (isSmooth: boolean) => {
  document.documentElement.style.scrollBehavior = isSmooth ? 'smooth' : 'auto';
};

export function useSmoothScroll() {
  const router = useRouter();

  React.useEffect(() => {
    setSmoothScroll(true);
    const handleStart = () => setSmoothScroll(false);
    const handleStop = () => setSmoothScroll(true);

    router.events.on('routeChangeStart', handleStart);
    router.events.on('routeChangeComplete', handleStop);
    router.events.on('routeChangeError', handleStop);

    return () => {
      router.events.off('routeChangeStart', handleStart);
      router.events.off('routeChangeComplete', handleStop);
      router.events.off('routeChangeError', handleStop);
    };
  }, [router]);
}

const App = ({ Component, pageProps }: AppProps) => {
  globalStyles();

  const { route } = useRouter();
  useSmoothScroll();

  return (
    <>
      <FormProvider>
        <LayoutMenuMain>
          <Flex
            css={{
              alignItems: 'center',
              justifyContent: 'space-between',
              padding: '$spacingMainMobile',

              '@laptopAndUp': {
                padding: '$spacing-2x',
              },
            }}
          >
            <Link href="/">
              <a>
                <LogoMain style={{ height: '4em' }} />
              </a>
            </Link>
            <MenuButton />
          </Flex>
          <MainNav>
            <MainNavItem active={route === '/'} linkUrl="/">
              Home
            </MainNavItem>
            <MainNavItem
              active={route.startsWith('/ueber-mich')}
              linkUrl="/ueber-mich"
            >
              Über mich
            </MainNavItem>
            <MainNavItem
              active={route.startsWith('/leistungen-preise')}
              linkUrl="/leistungen-preise"
            >
              Leistungen & Preise
            </MainNavItem>
            <MainNavItem
              active={route.startsWith('/kontakt')}
              linkUrl="/kontakt"
            >
              Kontakt
            </MainNavItem>
          </MainNav>
        </LayoutMenuMain>

        <Component {...pageProps} />

        <Flex
          css={{
            paddingInline: '$spacingMainMobile',
            paddingBlock: '$spacing-4x',
            '@tabletAndUp': {
              paddingBlock: '$spacing-8x',
            },
          }}
        >
          <Flex
            css={{
              maxWidth: '$laptop',
              width: '100%',
              margin: 'auto',
              justifyContent: 'space-between',
              flexDirection: 'column',
              gap: '$spacing-3x',

              '@tabletAndUp': {
                flexDirection: 'row',
              },
            }}
          >
            <LayoutText align="center" color="$skin-grey">
              © 2022 Hubert Gabriel
            </LayoutText>
            <LayoutFlow
              gap="$spacing-3x"
              css={{
                flexDirection: 'column',

                '@tabletAndUp': {
                  flexDirection: 'row',
                },
              }}
            >
              <Link href="/agb" passHref={true}>
                <CopyText align="center" color="skinGrey" variant="body" as="a">
                  AGBs
                </CopyText>
              </Link>
              <Link href="/datenschutz" passHref={true}>
                <CopyText align="center" color="skinGrey" variant="body" as="a">
                  Datenschutz
                </CopyText>
              </Link>
              <Link href="/impressum" passHref={true}>
                <CopyText align="center" color="skinGrey" variant="body" as="a">
                  Impressum
                </CopyText>
              </Link>
            </LayoutFlow>
          </Flex>
        </Flex>
      </FormProvider>
    </>
  );
};

export default App;
