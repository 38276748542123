import * as React from 'react';

const SvgHamburger = (
  props: React.SVGProps<SVGSVGElement> & { title?: string },
) => (
  <svg
    width={32}
    height={32}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <title>{props?.title || 'hamburger'}</title>
    <g clipPath="url(#a)">
      <path
        d="M4 24h24v-2.667H4V24Zm0-6.667h24v-2.666H4v2.666ZM4 8v2.667h24V8H4Z"
        fill="#797A7C"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h32v32H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default SvgHamburger;
